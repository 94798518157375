import React, { useEffect } from 'react';
import { CToast, CToastHeader } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
export default ({ message, type }) => {
  const notification = useSelector((state) => state.notification);
  const notificationIndex = useSelector((state) => state.notificationIndex);
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: 'hideNotification' });
    }, 5000);
    return () => clearTimeout(timer);
  }, [notificationIndex]);
  return (
    <CToast animation={true} autohide={true} visible={notification.show} color={notification.type} style={{zIndex: 9999}}>
      <div className="d-flex align-items-center justify-content-between py-2 px-3">
        <div className='text-white'>{notification.message}</div>
        {/* <CIcon className='text-white' style={{ marginLeft: '8px', width: '12px' }} o
          nClick={() => { dispatch({ type: 'hideNotification' }) }} icon={cilX} /> */}
      </div>
    </CToast>
  )
}
